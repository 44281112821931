@font-face {
  font-family: DMSans;
  src: url(../../assets/fonts/DMSans-Regular.ttf);
}

@font-face {
  font-family: BakbakOne;
  src: url(../../assets/fonts/BakbakOne-Regular.ttf);
}

@font-face {
  font-family: Exo2;
  src: url(../../assets/fonts//Exo2-Regular.ttf);
}

.BakbakOne {
  font-family: BakbakOne;
}

.Exo2 {
  font-family: Exo2;
}

:root {
  --fs-14: clamp(0.65rem, 1.4vw, 0.875rem);
  --fs-18: clamp(0.75rem, 1.5vw, 1.25rem);
  --fs-20: clamp(0.85rem, 1.5vw, 1.5rem);
  --fs-24: clamp(1rem, 1.5vw, 1.5rem);
  --fs-32: clamp(1.25rem, 2.25vw, 2rem);
  --fs-36: clamp(1.35rem, 2.35vw, 2.25rem);
  --fs-40: clamp(1.45rem, 2.45vw, 2.5rem);
  --fs-48: clamp(2rem, 2.5vw, 3rem);
  --fs-64: clamp(2.25rem, 4.5vw, 4rem);
  --fs-96: clamp(2.5rem, 5vw, 6rem);
}


h1, h2, h3, h4, h5, h5, p {
  margin: 0;
}

h1 {
  font-size: var(--fs-96);
}
h2 {
  font-size: var(--fs-64);
}
h3 {
  font-size: var(--fs-48);
}
h4 {
  font-size: var(--fs-32);
}
h5 {
  font-size: var(--fs-24);
}

.fs-14 {
  font-size: var(--fs-14);
}
.fs-18 {
  font-size: var(--fs-18);
}
.fs-20 {
  font-size: var(--fs-20);
}
.fs-24 {
  font-size: var(--fs-24);
}
.fs-30 {
  font-size: var(--fs-30);
}
.fs-32 {
  font-size: var(--fs-32);
}
.fs-36 {
  font-size: var(--fs-36);
}
.fs-40 {
  font-size: var(--fs-40);
}
.fs-48 {
  font-size: var(--fs-48);
}
.fs-64 {
  font-size: var(--fs-64);
}
.fs-96 {
  font-size: var(--fs-96);
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}

h2 > *, h3 > *, h4 > * {
  font-family: inherit;
  font-size: inherit;
}