.cookie-container {
  width: 330px !important;
  background: #fff !important;
  color: var(--color-darkgrey) !important;
  text-align: center;
  font-weight: 700;
  border: 1px solid var(--color-darkgrey);
}
.cookie-learn {
  border-bottom: 2px solid var(--color-darkgrey);
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
}
.cookie-button-wrapper {
  width: 100%;
  text-align: center;
}
.cookie-accept {
  background: var(--color-darkgrey) !important;
  color: #fff !important;
  font-weight: 700;
  width: 60%;
  margin-top: 0px !important;
  margin-bottom: 50px !important;
}

.modal-title {
  margin-bottom: 16px;
}
.modal-section-header {
  font-weight: 700;
  margin: 20px 0 15px;
}
.modal-content i {
  margin-bottom: 5px;
  display: block;
}
.modal-content p {
  margin-bottom: 10px;
}
.modal-content a {
  /* color: var(--color-darkgrey); */
}


@media (min-width: 768px) {
  .cookie-container {
    left: 40px !important;
    bottom: 40px !important;  
  }
}