.container {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
}
.title {
  font-size: 30px;
  background: url(assets/images/bg_team.png);
  padding: 40px 50px;
  display: inline-block;
  white-space: nowrap;
}
.title strong {
  font-family: inherit;
}
.title .highlight {
  padding: 15px;
  color: var(--color-darkgrey);
  background: var(--color-yellow);
  margin-right: 10px;
}
.content {
  padding: 19px 30px 38px;
}
.content-top {
  font-size: 20px;
  margin-bottom: 32px;
}
.content-bottom {
  font-size: 14px;
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .title {
    font-size: 48px;
    padding-bottom: 60px;
    padding-right: 30px;
  }
  .content-top {
    font-size: 24px;
  }
  .content-bottom {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
  }
  .content {
    padding: 38px 50px;
    /* width: 70%; */
  }
  .content-top {
    font-size: 32px;
    margin-bottom: 36px;
  }
  .content-bottom {
    font-size: 20px;
    margin-bottom: 30px;
  }
  
}