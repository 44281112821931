.container {
  padding: 150px 0 30px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.description {
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 45px;
}
.user {
  display: flex;
  align-items: center;
}
.user-data {
  display: flex;
  flex-direction: column;
}
.img-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}
.img-wrapper img {
  max-width: 100%;
  height: 100%;
}
.user-picture {
  position: absolute;
  top: 0;
  left: 0;
}


.logo-wrapper {
  margin: 50px 0 100px;
  padding: 0 30px;
}