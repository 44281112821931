.section {
  display: flex;
  flex-direction: column;
  padding: 35px 0;
  align-items: center;
}
.section-left {
  display: flex;
  align-items: center;
}
.section-left img {
  height: 40px;
  margin-right: 30px;
}
.section-right {
  display: flex;
  align-items: center;
}
.section-right img {
  height: 89px;
  margin-right: 28px;
}
.section-right .code {
  background: var(--color-yellow);
  padding: 8px 12px;
}
.links {
  margin-bottom: 15px;
}
.link {
  display: inline-block;
  color: #606773;
  position: relative;
}
.link:not(:last-child) {
  padding-right: 10px;
  margin-right: 10px;
}
.link:not(:last-child)::after {
  content: "/";
  position: absolute;
  right: 0;
}
.copyright {
  color: #9CAEC0;
}

@media (max-width: 575px) {
  .section {
    row-gap: 30px;
  }
  .section img {
    margin-right: 15px;
  }
  .copyright {
    font-size: clamp(0.75rem, 2.5vw, 1rem);
  }
}

@media (min-width: 576px) {
  .section {
    flex-direction: row;
    justify-content: space-between;
  }
}