.title {
  font-size: 20px;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.title span {
  padding: 8px 12px;
}
.title .highlight {
  background: var(--color-yellow);
}

.map {
  width: 100%;
}

@media (min-width: 768px) {
  .title {
    font-size: 36px;
  }
}

@media (min-width: 1200px) {
  .title {
    display: block;
  }
}