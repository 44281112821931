.container {
  padding: 34px 18px 32px 15px;
  display: flex;
  flex-direction: column;
  background: #E9EEF2;
  gap: 30px 70px;
}
.img-wrapper {
  width: 50%;
}
.img-wrapper img {
  max-width: 100%;
}

@media (min-width: 768px) {
  .container {
    padding: 44px 78px 32px 50px;
  }
  .description {
    font-size: 23px;
  }
}
@media (min-width: 992px) {
  .container {
    flex-direction: row;
    align-items: center;
  }
  .img-wrapper {
    width: 30%;
    flex-shrink: 0;
  }
}
@media (min-width: 1200px) {
  .container {
    padding: 54px 128px 42px 100px;
  }
}