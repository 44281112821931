.content-container {
  position: relative;
  padding: 3em 0;
}
.title {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.title h4 {
  display: inline-block;
  padding-right: 10px;
  background-color: var(--color-green);
}
.title::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #e061b6;
  top: 50%;
  z-index: -1;
}
/* .content h4 {
  font-weight: 400;
} */
.decorator {
  position: absolute;
  left: -30px;
  bottom: 0;
}

.content-1 {
  font-family: "DM Sans";
  font-size: 2.8em;
  font-weight: 400;
  color: #333;
  white-space: pre;
}

.content-2 {
  font-family: "BakbakOne";
  font-size: 4em;
  color: #333;
}
.rectangle {
  width: 25em;
  margin-bottom: -0.3em;
  margin-left: -3em;
}

@media screen and (max-width: 1180px) {
  .content-1 {
    font-size: 1.5em;
  }
  .content-2 {
    font-size: 2em;
  }
  .content-container {
    padding: 1.5em 0;
  }
}

@media screen and (max-width: 768px) {
  .content-1 {
    font-size: 1.2em;
    white-space: inherit;
  }
  .content-2 {
    font-size: 1.6em;
  }
  .rectangle {
    width: 18em !important;
    margin-left: 0;
  }
}
