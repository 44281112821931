.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 30px;
  bottom: 100px;
  z-index: 10;
}

.btn-floating {
  display: flex;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.btn-floating .img-wrapper {
  width: 100%;
  height: 100%;
}
.img-wrapper img {
  max-width: 100%;
  max-height: 100%;
}
.btn-toggler {
  background: var(--color-darkgrey);
  padding: 15px;
  border: 1px solid var(--color-yellow);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}
.btn-toggler .icon-black {
  display: none;
}
.btn-toggler:hover {
  background: var(--color-yellow);
}
.btn-toggler:hover .icon-white {
  display: none;
}
.btn-toggler:hover .icon-black {
  display: block;
}

.btn-download-group {
  position: relative;
  margin-bottom: 10px;
}
.btn-downloads {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  text-align: right;
}
.btn-download {
  display: inline-flex;
  justify-items: flex-end;
  gap: 5px;
  align-items: center;
  background: var(--color-darkgrey);
  border-radius: 32px;
  padding: 12px 30px;
  color: #fff;
  cursor: pointer;
  border: 1px solid var(--color-yellow);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
  white-space: nowrap;
}
.btn-download:hover {
  background: var(--color-yellow);
  color: var(--color-darkgrey);
  border-color: var(--color-darkgrey);
  text-decoration: none;
}
.btn-download img {
  width: 20px;
  height: auto;
}
.btn-download span {
  display: none;
}

.modal-title {
  font-family: "BakbakOne";
  font-size: 40px;
  margin-bottom: 20px;
  line-height: 1;
}
.modal-input {
  padding: 8px 16px;
  width: 100%;
  border-radius: 0;
  border: 1px solid var(--color-darkgrey);
  margin-bottom: 16px;
}
.modal-button {
  background-color: var(--color-yellow);
  width: 100%;
  padding: 8px 16px;
  font-family: "BakbakOne";
  text-align: center;
  border: none;
  cursor: pointer;
}
.modal-button[disabled] {
  cursor: not-allowed;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }
  .btn-download-group {
    margin-bottom: 0;
  }
  .btn-download span {
    display: inline-block;
  }
  .btn-download label {
    display: none;
  }
}