.container {
  display: flex;
  gap: 20px;
  padding: 40px;
  /* background-color: #e5e5e5; */
  width: 100%;
}
.container > * {
  /* flex: 1 1 0; */
  width: 50%;
}

@media (max-width: 991px) {
  .container {
    flex-direction: column;
  }
  .container > * {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .container {
    padding: 40px 0;
    /* gap: 0; */
  }
}