.title {
  font-size: 2em;
  padding: 3em;
}

.subtitle {
  font-family: "DM Sans";
  color: #333333;
  margin-top: 2em;
  font-size: 1em;
  line-height: 1.5;
}

.container-box {
  display: flex;
  margin-top: -0.5em;
  min-height: 28em;
  width: 100%;
  position: relative;
}

.available-now-container {
  padding: 3em 4em;
  display: flex;
  /* transition: width 0.3s; */
  /* position: relative; */
  overflow: hidden !important;
}
.available-now-container-2 {
  padding: 3em 4em;
  display: flex;
  /* transition: width 0.3s; */
  /* position: relative; */
  overflow: hidden !important;
}

/* .available-now-container:hover {
  width: 100% !important;
}

.available-now-container:hover + .coming-soon-container {
  display: none;
} */

/* .available-now-content-hover { */
/* transform: opacity 1s, linear; */
/* opacity: 0; */
/* font-size: 0.1em; */
/* } */

/* .available-now-container:hover .available-now-content-hover {
  opacity: 1 !important;
  font-size: 0.9em;
} */

.available-now-text {
  white-space: pre;
}

.available-now-container-duplicate {
  position: absolute;
  background: #333;
  min-height: 28em !important;
  opacity: 0;
  z-index: -10;
  padding: 3em 4em;
}

.available-now-container:hover + .available-now-container-duplicate {
  display: flex !important;
  position: absolute !important;
  z-index: 10 !important;
}

.available-now-container-duplicate:hover {
  display: flex !important;
  position: absolute !important;
  z-index: 10 !important;
  animation-name: example;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

/* .coming-soon-container-duplicate-first-content {
    width: 20%;
    padding: 3em 4em;
  } */

.coming-soon-container {
  background: #333;
  /* padding: 3em 4em; */
  position: relative;
  overflow: hidden !important;
}

.coming-soon-container-duplicate {
  position: absolute;
  background: #333;
  min-height: 28em !important;
  opacity: 0;
  /* opacity: 1; */
  z-index: -10;
}

.coming-soon-container:hover + .coming-soon-container-duplicate {
  display: flex !important;
  position: absolute !important;
  z-index: 10 !important;
}

.coming-soon-container-duplicate:hover {
  display: flex !important;
  position: absolute !important;
  z-index: 10 !important;
  animation-name: example;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.coming-soon-container-duplicate-first-content {
  width: 26%;
  padding: 3em 4em;
}

.coming-soon-mobile {
  display: none;
}

.coming-soon-desktop {
  color: white;
  font-weight: 700;
  font-size: 2em;
  font-family: "Exo" !important;
}

.i-want-to-know-more {
  padding: 0.5em;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 700;
  margin-top: 0.5em;
  white-space: pre;
}

.i-want-to-know-more:hover {
  opacity: 0.8;
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 100;
  top: 0;
  left: 0;
}

.coming-soon-probably {
  background-color: #575757;
  display: inline-block;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  font-size: 0.9em;
  font-family: "DM Sans";
  font-weight: 700;
  color: white;
}

.available-now-first-content-container {
  width: 20%;
}

.modal-content-body {
  background-color: white;
  border-radius: 1em;
  padding: 0.8em 1.6em 2em;
  width: 33em;
}
.modal-close {
  font-size: 1.5em;
  cursor: pointer;
  text-align: right;
}
.modal-content-title {
  font-family: "BakbakOne";
  font-size: 2.3em;
  white-space: pre;
  color: #333;
  margin-bottom: 0.5em;
}
.modal-input {
  padding: 1em 1.5em;
  width: 100%;
  border-radius: 0em;
  border: 1px solid black;
}
.modal-button {
  background-color: #70ec68;
  width: 100%;
  padding: 0.5em;
  font-family: "BakbakOne";
  text-align: center;
  margin-top: 0.7em;
  border: none;
}

@keyframes example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1180px) {
  .modal-content-body {
    width: 96% !important;
    padding: 0.8em 1.3em 1.4em !important;
  }
  .modal-close {
    font-size: 1em !important;
  }
  .modal-content-title {
    white-space: unset !important;
    font-size: 1.2em !important;
  }
  .title {
    font-size: 1.6em !important;
    padding: 2em !important;
  }
  .subtitle {
    font-size: 0.9em !important;
  }
  .container-box {
    flex-direction: column;
    min-height: auto;
  }
  .available-now-container {
    flex-direction: column !important;
    width: 100% !important;
    padding: 2em !important;
  }
  .available-now-container-2 {
    display: flex;
    flex-direction: column !important;
  }
  .available-now-first-content-container {
    width: 30% !important;
  }
  .available-now-container-duplicate:hover {
    position: relative !important;
  }

  .available-now-container:hover + .available-now-container-duplicate {
    position: relative;
  }
  .available-now-container {
    display: none !important;
  }
  .available-now-text {
    white-space: nowrap !important;
    margin-top: 0.2em;
    /* margin-bottom: 0.3em; */
  }
  .available-now-container-duplicate {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    position: initial;
    width: 100% !important;
    opacity: 1 !important;
    z-index: 1 !important;
    min-height: auto !important;
  }
  .coming-soon-container:hover + .coming-soon-container-duplicate {
    position: relative !important;
  }

  .coming-soon-container-duplicate:hover {
    position: relative !important;
  }
  .coming-soon-container {
    display: none !important;
  }
  .coming-soon-mobile {
    color: white;
    font-family: "Exo" !important;
    font-weight: 700;
    font-size: 1.7em;
    width: 100%;
    white-space: nowrap;
    display: block !important;
  }

  .coming-soon-desktop {
    display: none !important;
  }
  .coming-soon-container-duplicate {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    position: initial;
    width: 100% !important;
    opacity: 1 !important;
    z-index: 1 !important;
    min-height: auto !important;
    padding: 0em 0em 2em;
  }
  .coming-soon-container-duplicate-first-content {
    padding: 2em 4em 1em !important;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 1.2em !important;
    padding: 2em !important;
  }
  .subtitle {
    font-size: 0.9em !important;
    margin-top: 1em !important;
  }

  .container-box {
    flex-direction: column;
    min-height: auto !important;
  }

  .available-now-container {
    flex-direction: column !important;
    width: 100% !important;
    padding: 2em !important;
  }
  .available-now-container-2 {
    flex-direction: column !important;
    width: 100% !important;
    padding: 2em !important;
  }
  .available-now-first-content-container {
    width: 100% !important;
  }

  .available-now-container-duplicate:hover {
    position: relative !important;
  }

  .available-now-container:hover + .available-now-container-duplicate {
    position: relative;
  }
  .available-now-text {
    white-space: nowrap !important;
    font-size: 1.5em !important;
    margin-top: 0.2em;
    /* margin-bottom: 0.3em; */
  }
  .available-now-container {
    display: none !important;
  }
  .available-now-container-duplicate {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    position: initial;
    width: 100% !important;
    opacity: 1 !important;
    z-index: 1 !important;
    min-height: auto !important;
    padding: 2em !important;
  }

  .coming-soon-container:hover + .coming-soon-container-duplicate {
    position: relative !important;
  }

  .coming-soon-container-duplicate:hover {
    position: relative !important;
  }

  .coming-soon-container {
    display: none !important;
  }
  .coming-soon-container-duplicate {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    position: initial;
    width: 100% !important;
    opacity: 1 !important;
    z-index: 1 !important;
    min-height: auto !important;
  }
  .coming-soon-container-duplicate-first-content {
    padding: 2em 2em 0.5em !important;
    width: 100%;
  }
  .coming-soon-desktop {
    display: none !important;
  }
  .coming-soon-mobile {
    color: white;
    font-family: "Exo" !important;
    font-weight: 700;
    font-size: 1.7em;
    width: 100%;
    white-space: nowrap;
    display: block !important;
  }

  .i-want-to-know-more {
    margin-bottom: 0.5em;
  }
}
