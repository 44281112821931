.page-scroll {
  width: 100%;
  background-color: var(--color-darkgrey);
}
.container {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 30px;
  text-align: center;
}
.content h2 {
  font-family: BakbakOne;
  color: var(--color-green);
  line-height: 0.85;
  margin-bottom: 10px;
  font-weight: 400;
}
.content p {
  color: #fff;
  font-size: var(--fs-24);
}

@media (max-width: 575px) {
  .img-wrapper {
    margin-bottom: 15px;
  }
  .img-wrapper img {
    height: 100px;
  }
  .content p {
    margin-bottom: 30px;
  }
}

@media (min-width: 576px) {
  .container {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 100px;
    row-gap: 100px;
    padding: 40px 0;
    align-items: center;
  }  
}