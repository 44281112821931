.title {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 40px;
}
.title img {
  width: 24px;
  margin-right: 16px;
}
.title h4 {
  font-size: 20px;
}

.container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 35px;
}
.location h4 {
  font-size: 16px;
  margin-bottom: 8px;
}

@media (min-width: 576px) {
  .container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .location h4 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .title img {
    width: 36px;
  }
  .title h4 {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .title h4 {
    font-size: 30px;
  }
  .location h4 {
    font-size: 24px;
  }
}