.container {
}
.title {
  text-align: center;
  margin: 34px 0;
}
.title span {
  background: var(--color-yellow);
  text-transform: uppercase;
  padding: 8px 16px;
}
.news-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 10px;
}
.news {
  background: var(--color-darkgrey);
  height: 400px;
  position: relative;
}
.news .news-content {
  position: absolute;
  left: 30px;
  bottom: 50px;
}
.news .news-title {
  color: var(--color-yellow);
  font-size: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
}
.news p {
  color: #fff;
}
.readmore-container a {
  background: var(--color-darkgrey);
  color: #fff;
  display: block;
  text-align: right;
  padding: 23px 15px;
}

.news .news-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  filter: brightness(70%);
}

.read-all {
  padding: 27px 0;
  background: #cafc0e;
  cursor: pointer;
}

.read-all-text {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 12px;
}

@media (min-width: 768px) {
  .news-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .news .news-content {
    left: 50px;
  }
  .news .news-title {
    font-size: 26px;
  }
}

@media (min-width: 1200px) {
  .news .news-title {
    font-size: 36px;
  }
}
