.title {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 40px;
}
.title img {
  width: 24px;
  margin-right: 16px;
}
.title h4 {
  font-size: 20px;
}

.social-container {
  display: flex;
  gap: 25px;
  margin-bottom: 25px;
}
.description {

}

@media (min-width: 768px) {
  .container {
    width: 40%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 30%;
  }
  .title img {
    width: 36px;
  }
  .title h4 {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .title h4 {
    font-size: 30px;
  }
}