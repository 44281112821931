.content-top-container {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 80px 0 40px;
}

@media (min-width: 768px) {
  .content-top-container {
    flex-direction: row;
  }
}