.bg-logo {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  /* min-height: 250px; */
  margin-top: -20px;
}
.content {
  padding: clamp(5rem, 12vw, 9.5rem) 0;
  position: relative;
  z-index: 1;
}
.title {
  color: #fff;
  margin-bottom: 60px;
}
.title span + span {
  margin-left: 5px;

}
.title .highlight {
  background: var(--color-yellow);
  color: var(--color-darkgrey);
  padding: 4px 14px;
}
.content p {
  color: #fff;
  padding-right: 20px;
}
.description {
  color: white;
  line-height: 1.7;
  /* white-space: pre; */
  font-size: 1.2em;
}
.description img {
  max-width: 100%;
}

@media screen and (max-width: 1180px) {
  .title {
    margin-bottom: 1.5em !important;
    font-weight: 700;
  }
  .description {
    font-size: 0.9em !important;
    width: 100% !important;
    padding: 1em !important;
  }

  .bg-logo {
    width: 32% !important;
  }
  .description {
    white-space: unset !important;
  }
}

@media screen and (max-width: 768px) {
  .description {
    white-space: unset !important;
  }
}
