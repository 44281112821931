:root {
  --color-green: #70EC68;
  --color-purple: #AE71FC;
  --color-darkgrey: #333333;
  --color-lightgrey: #929696;
  --color-white : #FFFFFF;
  --color-purple2: #6C36FE;
  --color-yellow: #CAFC0E;
}
#root {
  overflow: hidden;
}

* {
  box-sizing: border-box;
  font-family: DMSans;
}

html, body {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  /* list-style-image: url(../../assets/images/icon_bullet_green.png); */
  padding-left: 25px;
}
li {
  margin-bottom: 10px;
}
a {
  color: var(--color-purple);
  font-size: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.fullwidth-carousel .carousel .slide iframe {
  width: 100%;
  margin: 0 0 40px;
}
.custom-carousel-arrow {
  width: 30px;
  height: 30px;
  background: var(--color-yellow);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 1;
  cursor: pointer;
}
.custom-carousel-arrow.arrow-prev {
  left: 30px;
}
.custom-carousel-arrow.arrow-next {
  right: 30px;
}
.custom-carousel-arrow:before {
  content: "";
  display: inline-block;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
.custom-carousel-arrow.arrow-prev:before {
  border-right: 7px solid var(--color-darkgrey);
}
.custom-carousel-arrow.arrow-next:before {
  border-left: 7px solid var(--color-darkgrey);
}

@media (min-width: 576px) {
  .custom-carousel-arrow {
    width: 35px;
    height: 35px;
  }
  .custom-carousel-arrow.arrow-prev {
    left: 35px;
  }
  .custom-carousel-arrow.arrow-next {
    right: 35px;
  }
  .custom-carousel-arrow:before {
    border-top-width: 10px;
    border-bottom-width: 10px;
  }
  .custom-carousel-arrow.arrow-prev:before {
    border-right-width: 10px;
    margin-left: -5px;
  }
  .custom-carousel-arrow.arrow-next:before {
    border-left-width: 10px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  .custom-carousel-arrow {
    width: 45px;
    height: 45px;
  }
  .custom-carousel-arrow.arrow-prev {
    left: 45px;
  }
  .custom-carousel-arrow.arrow-next {
    right: 45px;
  }
  .custom-carousel-arrow:before {
    border-top-width: 15px;
    border-bottom-width: 15px;
  }
  .custom-carousel-arrow.arrow-prev:before {
    border-right-width: 15px;
  }
  .custom-carousel-arrow.arrow-next:before {
    border-left-width: 15px;
  }
}