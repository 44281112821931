.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
  z-index: 1000;
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.modal {
  background: #fff;
  width: 600px;
  max-width: 85%;
  padding: 24px;
  overflow: auto;
  max-height: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}