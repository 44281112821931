.container {
  padding: 34px 18px 32px 15px;
}

.title {
  font-size: 20px;
  margin-bottom: 1em;
  color: #333;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
}
.title span {
  padding: 0.3em 0.3em 0.2em;
  font-family: "BakbakOne";
}
.title strong {
  font-family: inherit;
}
.title .highlight {
  background-color: var(--color-yellow);
}

.projects {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}

.flipper {
  position: relative;
  width: 100%;
  height: 360px;
  -webkit-transition: 0.6s;
	-webkit-transform-style: preserve-3d;

	-moz-transition: 0.6s;
	-moz-transform-style: preserve-3d;
  
  -o-transition: 0.6s;
	-o-transform-style: preserve-3d;

	transition: 0.6s;
	transform-style: preserve-3d;
}
.flipper.flipped {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.btn-toggler {
  padding: 8px 24px;
  background: transparent;
  border: 1px solid var(--color-yellow);
  color: var(--color-yellow);
  margin-top: 12px;
  font-weight: 700;
  cursor: pointer;
}
.flipper:nth-child(even) .front {
  background: var(--color-yellow);
  color: var(--color-purple2);
}
.flipper:nth-child(odd) .front {
  background: var(--color-purple2);
  color: var(--color-yellow);
}

.flipper:nth-child(even) .front .name {
  color: var(--color-purple2);
}
.flipper:nth-child(even) .btn-toggler {
  border-color: var(--color-purple2);
  color: var(--color-purple2);
}
.flipper.flip {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.flipper .front {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.flipper .back {
  padding: 10px;
  background: var(--color-darkgrey);
  color: #fff;
  cursor: pointer;

  -webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
	transform: rotateY(180deg);
}
.flipper .front, .flipper .back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  -webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
	backface-visibility: hidden;
}
.flipper .back .name {
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .container {
    padding: 44px 78px 32px 50px;
  }
  .title {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  .projects {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .flipper .front.bg-purple {
    background: var(--color-purple2);
    color: var(--color-yellow);
  }
  .flipper .front.bg-purple .name {
    color: var(--color-yellow);
  }
  .flipper .front.bg-purple .btn-toggler {
    color: var(--color-yellow);
    border-color: var(--color-yellow);
  }

  .flipper .front.bg-yellow {
    background: var(--color-yellow);
    color: var(--color-darkgrey);
  }
  .flipper .front.bg-yellow .name {
    color: var(--color-purple2);
  }
  .flipper .front.bg-yellow .btn-toggler {
    color: var(--color-purple2);
    border-color: var(--color-purple2);
  }
}

@media (min-width: 1200px) {
  .container {
    padding: 54px 128px 42px 100px;
  }
  .projects {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .flipper:nth-child(even) .front {
    background: var(--color-yellow);
    color: var(--color-purple2);
  }
  .flipper:nth-child(even) .front .name {
    color: var(--color-purple2);
  }
  .flipper:nth-child(even) .front .btn-toggler {
    color: var(--color-purple2);
    border-color: var(--color-purple2);
  }
  
  .flipper:nth-child(odd) .front {
    background: var(--color-purple2);
    color: var(--color-yellow);
  }
  .flipper:nth-child(odd) .front .name {
    color: var(--color-yellow);
  }
  .flipper:nth-child(odd) .front .btn-toggler {
    color: var(--color-yellow);
    border-color: var(--color-yellow);
  }
}
