.container {
  /* padding: 34px 38px 50px 20px; */
  padding: 34px 20px 50px;
  position: relative;
  background: url(assets/images/bg_vision_mission.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.flex-container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
.title {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 20px;
}
.title strong {
  font-family: inherit;
}
.bg-image {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 210px;
  max-height: 40%;
  max-width: 35%;
}

.points {
  padding-left: 0;
  list-style: none;
}
.point {
  background: white;
  padding: 12px 18px;
}
.point-inner {
  position: relative;
  padding-left: 30px;
  text-align: left;
}
.point span {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
}
.quote-container {
  padding: 25px;
}
.quote {
  position: relative;
  background: #fff;
  border: 4px solid #000;
  padding: 57px 33px;
  text-align: center;
}
.quote img {
  width: 70px !important;
}
.quote-open {
  position: absolute;
  top: -20px;
  left: -30px;
}
.quote-close {
  position: absolute;
  right: -20px;
  bottom: -30px;
  transform: rotate(180deg);
}
.quoter {
  text-align: right;
  font-size: 20px;
  margin-top: 64px;
}

@media (min-width: 768px) {
  .container {
    /* padding: 44px 78px 50px 50px; */
    padding: 44px 50px 50px;
  }
  .title {
    font-size: 26px;
  }
  .quoter {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .container {
    padding: 54px 100px 50px;
  }
  .title {
    font-size: 32px;
  }
  .flex-container {
    flex-direction: row;
  }
  .flex-item {
    width: 50%;
  }
  .flex-left {
    padding-right: 10%;
  }
  .point {
    padding: 24px 35px;
  }
}