.container {
  background: url(assets/images/bg_vision_mission.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.flex {
  display: flex;
  padding-bottom: 100px;
}
.flex p {
  margin-top: 20px;
}
.title {
  width: 100%;
  line-height: 1;
  padding-top: 28px;
  margin-bottom: 36px;
  position: relative;
}
.title:after {
  display: none;
  content: "";
  position: absolute;
  right: 0;
  bottom: 30px;
  width: 82px;
  height: 7px;
  background: #AD26FF;
}
.container h4 {
  background: var(--color-purple2);
  color: #fff;
  padding: 8px 24px;
}

@media (max-width: 767px) {
  .flex {
    flex-direction: column;
    gap: 20px;
  }
}

@media (min-width: 576px) {
  .title {
    font-size: 40px;
    width: 65%;
  }
  .title:after {
    display: block;
    bottom: 17px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 42px;
    width: 55%;
  }
  .title:after {
    bottom: 17px;
    right: 20px;
  }
  .flex > :nth-child(1) {
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .title {
    font-size: 50px;
    width: 48%;
  }
}

@media (min-width: 1200px) {
  .title {
    width: 50%;
    font-size: 64px;
  }
}

@media (min-width: 1400px) {
  .title {
    width: 43%;
  }
  .title:after {
    bottom: 26px;
  }
}