.container {
  margin: 0 auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}

.col-sm-6,
.col-md-3, .col-md-4, .col-md-6, .col-md-12,
.col-lg-4, .col-lg-6, .col-lg-12 {
  width: 100%;
}

@media (max-width: 575px) {
  .container {
    padding: 0 15px;
  }
}

@media (min-width: 576px) {
  .container {
    width: 540px;
  }
  .col-sm-6 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-12 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-12 {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
  .col-xl-4 {
    width: 33.33%;
  }
  .col-xl-6 {
    width: 50%;
  }

}

@media (min-width: 1400px) {
  .container {
    width: 1320px;
  }
}