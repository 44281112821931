.top {
  height: 480px;
  background-color: #E9EEF2;
}
.container {
  margin-top: -480px;
  padding-top: 100px;
}
.container .title {
  /* display: inline-flex; */
  margin-bottom: 80px;
  text-transform: uppercase;
  align-items: center;
}
.title .highlight {
  background: var(--color-yellow);
  padding: 8px 12px;
  margin-right: 8px;
  display: inline-block;
}
.container .subtitle {
  margin-bottom: 38px;
  font-weight: 400;
  text-transform: uppercase;
}
.board-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  justify-content: center;
}
.board-container:not(:last-child) {
  border-bottom: 1px solid #9CAEC0;
  margin-bottom: 70px;
}
.board-container:last-child {
  margin-bottom: 60px;
}
.board-member {
  /* width: 33.33%; */
  width: 350px;
  padding: 0 30px;
  margin-bottom: 100px;
}
.image-container {
  position: relative;
  display: flex;
}
.image-container img {
  width: 100%;
}
.member-name {
  position: absolute;
  font-family: Exo2;
  left: 0;
  bottom: 0;
  padding: 7px 14px;
  font-weight: 700;
  background-color: var(--color-yellow);
}
.member-image {
  position: absolute;
  left: 0;
  bottom: 0;
}
.member-position {
  padding: 7px 10px;
  color: #F6F9FD;
  background-color: var(--color-darkgrey);
}

.company-structure-wrapper {
  padding: 0 30px;
}

@media (max-width: 767px) {
  .board-member {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .board-member {
    width: 100%;
  }
}