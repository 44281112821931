.intro-bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  animation-name: example;
  animation-duration: 2s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

.image-intro {
  animation-name: image;
  animation-duration: 2s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

@keyframes image {
  from {
  }
  to {
    transform: scale(-0.5);
  }
}

@keyframes example {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: -100;
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .image-intro {
    width: 100%;
  }
}
