.container {
  padding-top: 200px;
}

.title {
  margin-bottom: 80px;
}
.title .highlight {
  background: var(--color-yellow);
  padding: 5px 10px 10px;
}
.board-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
}
.board-container > * {
  padding: 0 30px;
}
.board-member {
  /* width: 33.33%; */
  padding: 0 30px;
  margin-bottom: 50px;
}
.image-container {
  position: relative;
  display: flex;
}
.image-container img {
  width: 100%;
}
.member-name {
  position: absolute;
  font-family: Exo2;
  left: 0;
  bottom: 0;
  padding: 7px 14px;
  font-weight: 700;
  background-color: var(--color-yellow);
}
.member-image {
  position: absolute;
  left: 0;
  bottom: 0;
}
.member-position {
  padding: 7px 10px;
  color: #F6F9FD;
  background-color: var(--color-darkgrey);
}

@media (max-width: 767px) {
  .board-member {
    /* width: 50%; */
  }
}

@media (max-width: 400px) {
  .board-member {
    /* width: 100%; */
  }
}