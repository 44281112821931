.section-container {
  overflow: hidden;
  padding: clamp(2rem, 3vw, 3rem);
}

.section-description {
  /* white-space: pre; */
}

@media (min-width: 992px) {
  .section-container {
    height: 50vh;
  }
  .section {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .section-content {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .section-content-inner {
    width: 100%;
    height: auto;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .section-content-inner::-webkit-scrollbar {
    width: 0;
  }
  .section-content:hover .section-content-inner {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
  }
  .section-description {
    display: none;
    opacity: 1;
    transform: translate(0);
    /* padding: 0 50px; */
  }
  .section-container:hover .section-description {
    display: initial;
  }
}