.container {
  margin-top: 3em;
  display: flex;
  justify-content: center;
}

.title {
  font-size: 2em;
  margin-bottom: 1em;
  color: #333;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
}
.title span {
  padding: 0.3em 0.3em 0.2em;
  font-family: "BakbakOne"
}
.title .highlight {
  background-color: var(--color-yellow);
}

.slide-wrapper {
  padding: 0 10%;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 2em;
  }

  .slide-wrapper {
    padding: 0 30px;
  }

  .title {
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }
}
