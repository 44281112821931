.container {
  /* padding-top: 415px; */
  /* padding-top: 135px; */
  /* background-color: #E9EEF2; */
}
.main-title {
  margin-bottom: 85px;
  font-weight: 400;
}

.section-container {
  padding: clamp(2rem, 3vw, 3rem);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  transition: background-size 1s ease;
  position: relative;
}
.section-container:hover {
  background-size: 110% 110%;
}
.section-container:hover:before {
  content: none;
}

.section {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.section-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.section-content-inner {
  width: 100%;
  height: auto;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.section-content-inner.w-auto {
  width: auto;
}
.section-description {
  margin: 0;
  transition: all 0.5s ease;
}
.section-description img {
  max-width: 100%;
}
.title {
  font-weight: 400;
  font-family: BakbakOne;
}

@media (max-width: 575px) {
  .container {
    /* padding-top: 275px; */
  }
  .main-title {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .section-content-inner::-webkit-scrollbar {
    width: 0;
  }
  .section-content:hover .section-content-inner {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
  }
  .section-content:hover .section-content-inner::-webkit-scrollbar {
    /* width: auto; */
  }
}

@media (max-width: 991px) {
  .section-icon img {
    height: 40px;
  }
  .section-container .title {
    margin-bottom: 20px;
  }
  .section-content {
    position: relative;
  }
}

@media (min-width: 992px) {
  .expandable-sections {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .section-container {
    position: absolute;
    width: 33.33%;
    height: 50vh;
    transition: width 0.3s ease, height 0.3s ease;
    top: 0;
  }
  .section-container:hover {
    width: 50%;
    height: 75vh;
    z-index: 1;
    color: var(--color-white);
    background-size: cover;
  }
  .section-container:nth-child(odd):hover {
    /* background-image: url(assets/images/bg_blurred_1.png); */
  }
  .section-container:nth-child(even):hover {
    /* background-image: url(assets/images/bg_blurred_2.png); */
  }
  .section-container:nth-child(4),
  .section-container:nth-child(5),
  .section-container:nth-child(6) {
    top: initial;
    bottom: 0;
  }
  .section-container:nth-child(3n + 1) {
    left: 0;
  }
  .section-container:nth-child(3n + 2) {
    left: 33.33%;
  }
  .section-container:nth-child(3n) {
    left: initial;
    right: 0;
  }

  .section-container:hover .title {
    margin-bottom: 30px;
  }

  .section-container:hover .section-content {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .section-description {
    display: none;
    opacity: 1;
    transform: translate(0);
  }
  .section-container:hover .section-description {
    display: initial;
  }
  .content-top .description-container {
    max-height: initial;
  }
  .section-container.corner-top-left:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    border: 1px solid var(--color-green);
    border-width: 8px 0 0 8px;
    width: 30px;
    height: 30px;
  }
  .section-container.corner-bottom-right:before {
    content: "";
    position: absolute;
    right: 20px;
    bottom: 20px;
    border: 1px solid var(--color-green);
    border-width: 0 8px 8px 0;
    width: 30px;
    height: 30px;
  }
}

/* V5 */

.title-highlight {
  font-weight: 700;
  font-family: "Exo";
  padding: 0.25em 0.35em 0.15em;
  color: #333;
}

.ul-li-container {
  margin-top: 1.4em;
}
.ul-li-custom {
  list-style: initial;
  font-size: 1.2em;
  margin-bottom: 0.3em;
}

.coming-soon-content-container {
  font-size: 1.3em;
  width: 100%;
  padding: 3em 0em;
}

.coming-soon-description {
  font-family: "DM Sans";
  margin-top: 0.5em;
  white-space: pre;
}

.available-now-full-content {
  font-family: "DM Sans";
  font-size: 2em;
  font-weight: 700;
}

.available-now-full-content-2 {
  font-size: 1.4em;
  margin-top: 0.2em;
}

.coming-soon-crypto-container {
  width: 100%;
  /* display: flex; */
  padding: 3em 1.5em;
}

.coming-soon-crypto-venturedao-container {
  font-size: 1.3em;
  margin-top: 0.7em;
  white-space: pre;
}

.coming-soon-crypto-title {
  font-size: 1.6em;
}

.coming-soon-description-title {
  font-family: "DM Sans";
  font-weight: 600;
  font-size: 1.6em;
}

@media screen and (max-width: 1180px) {
  .available-now-full-content-2 {
    font-size: 1.2em !important;
    margin-top: 1.5em !important;
    padding: 0;
  }
  .available-now-full-content {
    /* font-size: 0.9em !important; */
    margin-top: 1em !important;
    padding: 0;
  }
  .coming-soon-content-container {
    padding: 1.5em 3em;
  }
  .coming-soon-crypto-container {
    flex-direction: column;
    padding: 1em 4em;
  }
  .coming-soon-crypto-venturedao-container {
    font-size: 1.2em !important;
    white-space: unset !important;
  }
  .coming-soon-description-title {
    font-size: 1.3em !important;
  }
  .coming-soon-description {
    white-space: unset !important;
    font-size: 1em !important;
  }
}

@media screen and (max-width: 768px) {
  .title-highlight {
    padding: 0.15em 0.25em 0.05em;
  }

  .available-now-full-content {
    font-size: 1.2em !important;
    /* margin-top: 0.8em !important; */
    margin-top: 1em !important;
  }
  .available-now-full-content-2 {
    margin-top: 1em !important;
  }

  .coming-soon-content-container {
    font-size: 0.95em !important;
    padding: 1.5em 2em 1.5em !important;
  }

  .ul-li-container {
    margin-top: 0.5em;
  }
  .ul-li-custom {
    list-style: initial;
    font-size: 0.9em;
    margin-bottom: 0.2em;
  }
  .coming-soon-crypto-container {
    flex-direction: column;
    padding: 0 2em 2em;
  }
  .coming-soon-crypto-title {
    font-size: 1.3em !important;
  }
  .coming-soon-crypto-venturedao-container {
    font-size: 0.9em !important;
  }
  .coming-soon-content-container {
    padding: 1.5em 2em !important;
  }
  .coming-soon-crypto-container {
    flex-direction: column;
    padding: 1.5em 2em !important;
  }
}

/* .coming-soon-crypto-venturedao-container {
  background-color: #ffffff;
  min-height: 28em;
  width: 42.5%;
  margin-left: 15%;
  padding: 3em 1.5em;
}

.coming-soon-crypto-graceland-container {
  background-color: #333;
  min-height: 28em;
  width: 42.5%;
  padding: 3em 1.5em;
  color: white;
}

.coming-soon-crypto-desc {
  margin-top: 1.5em;
  font-size: 1.3em;
}

.coming-soon-crypto-probably {
  background-color: #4410cf;
  display: inline-block;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: "DM Sans";
  font-weight: 700;
  color: white;
} */

@media screen and (max-width: 768px) {
  /* .coming-soon-crypto-venturedao-container {
    width: 100% !important;
    margin-left: 0 !important;
    min-height: auto;
    padding: 2em;
  }
  .coming-soon-crypto-graceland-container {
    width: 100%;
    min-height: auto;
    padding: 2em !important;
  }
  .coming-soon-crypto-desc {
    margin-top: 1.5em;
    font-size: 1em;
  }
  .coming-soon-crypto-probably {
    padding: 0.3em 0.7em;
  } */
}
