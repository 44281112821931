.container {
  padding: 34px 18px 32px 15px;
  color: #fff;
  position: relative;
}
.title {
  display: inline-flex;
  align-items: center;
  margin-bottom: 40px;
}
.title span {
  font-size: 20px;
  font-family: BakbakOne;
  padding: 4px 8px;
}
.title .highlight {
  background: var(--color-yellow);
  color: var(--color-darkgrey);
}
.bg-line {
  position: absolute;
  bottom: 0;
  right: 10%;
  z-index: 1;
  max-width: 25%;
  min-width: 150px;
}

.reasons {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 20px 22px;
  position: relative;
  z-index: 2;
}
.reason {
  border: 2px solid #fff;
  padding: 13px 29px;
  background: var(--color-darkgrey);
}
.reason:hover {
  border-color: var(--color-yellow);
  background: var(--color-yellow);
  color: var(--color-darkgrey);
}

@media (min-width: 768px) {
  .container {
    padding: 44px 78px 32px 50px;
  }
  .title span {
    font-size: 28px;
  }
  .reasons {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    font-size: 18px;
  }
  .reason {
    padding: 26px 58px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding: 54px 100px;
  }
  .title span {
    font-size: 48px;
  }
  .reasons {
    font-size: 20px;
  }
}