.container {
  padding: 50px 30px 80px;
  position: relative;
}

.title {
  font-size: 24px;
  margin-bottom: 34px;
}
.title .highlight {
  background: var(--color-yellow);
  padding: 5px 10px 10px;
  display: inline-block;
}
.content-wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 30px;
  position: relative;
}
.content {
  position: relative;
  height: 100%;
  padding-bottom: 55px;
}
.content.bg-purple .content-title {
  background: var(--color-purple2);
  color: #fff;
}
.content.bg-yellow .content-title {
  background: var(--color-yellow);
  color: var(--color-darkgrey);
}
.content-title {
  font-family: "BakbakOne";
  font-size: 24px;
  height: 150px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 16px;
}
.content-description {
  font-size: 16px;
  padding: 22px;
}
.learn-more {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--color-yellow);
  padding: 8px 16px;
  font-size: 30px;
  cursor: pointer;
}

.sector-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 12px;
}
.sector {
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  height: 60px;
}
.sector:nth-child(odd) {
  background: var(--color-purple2);
  color: #fff;
}
.sector:nth-child(even) {
  background: var(--color-yellow);
  color: var(--color-darkgrey);
}

.modal-container {
  --black2: #1E1E1E;
  --grey: #7D7D7D;
  width: calc(100% - 30px);
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 85%;
  overflow: auto;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.modal-content-wrapper {
  padding: 16px;
  padding-bottom: 15px !important;
  overflow: auto;
}
.modal-close {
  position: absolute;
  top: 8px;
  right: 4px;
  cursor: pointer;
}
.modal-left {
  background: #fff;
  color: #000;
}
.modal-left .modal-title {
  font-family: BakbakOne;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 5px;
}
.modal-left .sector-parent {
  margin-bottom: 24px;
}
.modal-left .sector-pricing {
  background: var(--black2);
  color: #fff;
  display: inline-block;
  padding: 16px 20px;
  margin-bottom: 24px;
}
.modal-left .sector-description {

}
.modal-left .sector-description h5 {
  font-weight: 400;
}
.modal-left .sector-description code {
  /* visibility: hidden; */
  background: var(--color-yellow);
}
.modal-left .sector-description li {
  margin-bottom: 0;
}
.modal-right {
  background: var(--black2);
  color: #fff;
}
.modal-right .modal-title {
  margin-bottom: 32px;
}
.modal-right .modal-title span {
  font-style: italic;
}
.modal-right .stepper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-bottom: 1px solid var(--grey);
  padding-bottom: 16px;
  margin-bottom: 32px;
}
.modal-right .step {
  color: var(--grey);
  display: flex;
  align-items: center;
  gap: 8px;
}
.modal-right .step.active {
  color: #fff;
}
.modal-right .step.clickable {
  cursor: pointer;
}
.modal-right .step-number {
  background: linear-gradient(to right, var(--color-purple2), var(--color-yellow));
  padding: 1px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.modal-right .step-number span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--black2);
  border-radius: 50%;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
}
.form-row label {
  flex-shrink: 0;
  padding: 5px 0;
}
.form-row .input-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, var(--color-purple2), var(--color-yellow));
}
.form-row input {
  background: var(--black2);
  border: none;
  color: #fff;
  width: 100%;
  font-size: 16px;
}
.form-row input:focus {
  outline: none;
}
.form-row .input-wrapper + label,
.form-row label + .input-wrapper,
.form-row svg + input {
  margin-left: 5px;
}
.agreement {
  margin: 40px 0;
}
.agreement-item {
  margin-bottom: 12px;
}
.agreement-header {
  position: relative;
  padding-left: 24px;
  padding-right: 15px;
}
.agreement-header span {
  text-decoration: underline;
}
.agreement img {
  position: absolute;
  top: 0;
  left: 0;
}
.agreement-toggler {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.agreement-content {
  padding-top: 12px;
  padding-left: 24px;
}
.button-submit {
  background: var(--color-yellow);
  padding: 16px;
  text-align: center;
  width: 100%;
  margin-top: 40px;
}

.step2-container {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 16px;
}
.step2-title {
  font-size: 20px;
  margin-bottom: 16px;
}
.step2-duration {
  margin-bottom: 16px;
}
.step2-agenda {
  margin-top: 5px;
  padding-left: 20px;
}

@media (min-width: 576px) {
  .content-title {
    font-size: 40px;
  }
  .modal-left .modal-title {
    font-size: 40px;
  }
  .sector-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sector.bg-purple {
    background: var(--color-purple2);
    color: #fff;
  }
  .sector.bg-yellow {
    background: var(--color-yellow);
    color: var(--color-darkgrey);
  }
  .step2-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .form-row {
    flex-wrap: nowrap;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 80px 60px;
  }
  .title {
    font-size: 40px;
    margin-bottom: 50px;
  }
  .content-wrapper {
    /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
  }
  .content-title {
    height: 200px;
  }
  .sector-wrapper {
    /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
  }
  .sector {
    font-size: 20px;
    height: 80px;
  }
  /* .sector:nth-child(odd) {
    background: var(--color-purple2);
    color: #fff;
  }
  .sector:nth-child(even) {
    background: var(--color-yellow);
    color: var(--color-darkgrey);
  } */

  .modal-container {
    width: 90%;
  }
  .modal-content-wrapper {
    padding: 24px;
  }
  .modal-close {
    top: 16px;
    right: 16px;
  }
}

@media (min-width: 992px) {
  .content-wrapper {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .content-wrapper.expanded {
    margin-bottom: 380px;
  }
  .content-title {
    font-size: 32px;
  }
  .content-description {
    margin-bottom: 20px;
  }
  .title {
    margin-bottom: 75px;
    font-size: 48px;
  }
  .sector-wrapper {
    position: absolute;
    bottom: -30px;
    transform: translateY(100%);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sector {
    font-size: 24px;
    height: 110px;
  }
  .sector:nth-child(odd) {
    background: var(--color-purple2);
    color: #fff;
  }
  .sector:nth-child(even) {
    background: var(--color-yellow);
    color: var(--color-darkgrey);
  }

  .modal-container {
    /* overflow: hidden; */
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .modal-close {
    color: #fff;
  }
}

@media (min-width: 1200px) {
  .content-description {
    padding: 22px 34px;
  }
  .modal-content-wrapper {
    padding: 32px;
  }
  .content-wrapper.expanded {
    margin-bottom: 450px;
  }
  .modal-left .modal-title {
    font-size: 48px;
  }
  .sector {
    font-size: 28px;
    height: 130px;
  }
}