.container {
  padding: 20px 50px;
}
.container > :not(.bg-image) {
  z-index: 2;
}
.container h3 {
  margin-left: 15px;
}
.bg-image {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30%;
  height: 50%;
}
.anchor:hover {
  text-decoration: none;
}