.rdp-months {
  justify-content: center;
}
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--color-purple2);
}
.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: var(--color-purple2);
}
.rdp {
  --rdp-cell-size: 30px;
  margin: 0;
}
.rdp-caption-label {
  font-size: 100%;
}

@media (min-width: 1200px) {
  .rdp {
    --rdp-cell-size: 40px;
  }
}