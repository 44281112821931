.container {
  padding: 34px 18px 32px 15px;
  color: #fff;
}
.title {
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  margin-bottom: 60px;
}
.title span {
  font-size: 20px;
  font-family: BakbakOne;
  padding: 4px 8px;
}
.title .highlight {
  background: var(--color-yellow);
  color: var(--color-darkgrey);
}
.partner-list {
  display: flex;
  flex-direction: column;
}
.partner {
  margin-bottom: 50px;
}

.logo-wrapper {
  width: 150px;
  padding-right: 30px;
  flex-shrink: 0;
  margin-bottom: 5px;
}
.logo-wrapper img {
  max-width: 100%;
}

@media (min-width: 768px) {
  .container {
    padding: 44px 78px 32px 50px;
  }
  .title span {
    font-size: 28px;
  }
  .logo-wrapper {
    width: 150px;
  }
  .partner {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .container {
    padding: 54px 128px 42px 100px;
  }
  .title span {
    font-size: 48px;
  }
  .partner-list {
    flex-direction: row;
    column-gap: 50px;
  }
  .partner-left, .partner-right {
    width: 50%;
  }
}