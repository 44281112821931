.container {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 0 20px;
}
.logo {
  padding: 1em 0;
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
.logo img {
  max-width: 100%;
}
.lang-selector {
  color: var(--color-darkgrey);
  font-size: 14px;
  font-family: BakbakOne;
  flex-shrink: 0;
}
.lang-selector span {
  font-family: BakbakOne;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
}
.lang-selector span.active {
  color: var(--color-purple2);
}
.lang-selector span + span:before {
  content: "";
  position: absolute;
  top: 0;
  left: -2px;
  width: 4px;
  height: 100%;
  background: var(--color-darkgrey);
}

@media (min-width: 576px) {
  .lang-selector {
    font-size: 24px;
  }
}