.pos-rel {
  position: relative;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.text-center {
  text-align: center;
}
.m-0 {
  margin: 0;
}
.h-0 {
  height: 0;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.ws-prewrap {
  white-space: pre-wrap;
}
.img-wrapper img {
  max-width: 100%;
}
.smooth-transition {
  transition: all 0.5s ease;
}

.bgc-green {
  background-color: var(--color-green);
}
.bgc-darkgrey {
  background-color: var(--color-darkgrey);
}
.bgc-yellow {
  background-color: var(--color-yellow);
}
.bgc-purple2 {
  background-color: var(--color-purple2);
}
.color-white {
  color: var(--color-white);
}
.color-green {
  color: var(--color-green);
}
.color-darkgrey {
  color: var(--color-darkgrey);
}

.styled-title {
  padding: 5px 10px 10px;
  color: var(--color-darkgrey);
  background-color: var(--color-green);
  position: relative;
}
.styled-title.dark {
  color: #fff;
  background-color: var(--color-darkgrey);
}
.styled-title:before {
  --size: 12px;
  /* content: ""; */
  position: absolute;
  top: 0;
  right: calc(var(--size) * -1);
  border: var(--size) solid white;
  border-right: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  transform: translateY(calc(var(--size) * -1));
  rotate: -45deg;
}
.document-wrapper {
  margin-bottom: 15px;
}
.document {
  display: table-row;
  align-items: center;
}
.document .icon {
  padding: 15px 20px;
  display: table-cell;
  vertical-align: middle;
  background-color: var(--color-green);
}
.document .name {
  border: 2px solid var(--color-green);
  display: table-cell;
  vertical-align: middle;
  background-color: #fff;
  padding: 15px;
}