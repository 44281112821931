.container {
  padding: 140px 15px 120px;
}
.title {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}
.partners-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}