.container {
  padding: 110px 0 100px;
  background-color: #E9EEF2;
  position: relative;
}
.title {
  margin-bottom: 30px;
}
.title .highlight {
  background: var(--color-yellow);
  padding: 8px 12px;
  margin-right: 8px;
}
.column-left {
  padding-right: 50px;
}
.column-left p {
  margin-bottom: 30px;
}
.subtitle {
  margin-bottom: 25px;
}
.decorator {
  position: absolute;
  right: 10%;
  bottom: 0;
  margin-bottom: -28.5px;
}

.column-right {
  align-self: center;
}
.committee-members {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.committee-member {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.committee-name {
  background: var(--color-yellow);
  padding: 5px 10px 10px;
}

@media (max-width: 991px) {
  .decorator {
    height: 40px;
    margin-bottom: -20px;
  }
}

@media (max-width: 767px) {
  .flex {
    flex-direction: column;
  }  
}

@media (max-width: 575px) {
  .decorator {
    height: 35px;
    margin-bottom: -17.5px;
  }
}

@media (max-width: 320px) {
  .decorator {
    height: 20px;
    margin-bottom: -10px;
  }
}