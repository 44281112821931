.container {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
}
.title-wrapper {
  display: flex;
  align-items: center;
  padding: 0 15px 0 25px;
  margin-bottom: 40px;
}
.title-wrapper img {
  margin-right: 25px;
}
.title {
  color: var(--color-yellow);
  font-size: 24px;
}
.link-wrapper {
  text-align: right;
  padding-right: 25px;
  position: relative;
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
}
.link-wrapper a {
  padding: 12px 12px 12px 16px;
  background: var(--color-yellow);
  color: #333;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 2;
}
.link-wrapper a span {
  margin-right: 8px;
}
.link-wrapper img {
  position: absolute;
  z-index: 1;
  height: 75px;
  right: 0;
  margin-right: -50%;
  margin-top: -15px;
}

@media (min-width: 576px) {
  .link-wrapper img {
    margin-right: -35%;
  }
  .title {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .container {
    flex-direction: row;
    align-items: center;
  }
  .title-wrapper {
    width: 50%;
    margin-bottom: 0;
    padding-left: 50px;
  }
  .title {
    font-size: 40px;
  }
  .link-wrapper {
    width: 50%;
    padding-right: 50px;
  }
}
@media (min-width: 992px) {
  .title-wrapper {
    width: 70%;
    padding-left: 100px;
  }
  .link-wrapper {
    width: 30%;
  }
  .link-wrapper img {
    margin-right: -25%;
  }
}